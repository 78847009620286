import React from "react";
import styled from "styled-components";




export default function App() {
  return (
    <div className="text-center footerDiv">
      <p className="footerText">2022 © Parazo Digital Business Solutions Inc.</p>
    </div>
  );
}